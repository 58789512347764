import React, {useRef, useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import smoothscroll from 'smoothscroll-polyfill';
import styles from './tutorial.module.scss';
import Layout from '../../components/layout/layout';
import tutorial0 from '../../../static/tutorial/0.png';
import tutorial1 from '../../../static/tutorial/1.png';
import tutorial2 from '../../../static/tutorial/2.png';
import tutorial3 from '../../../static/tutorial/3.png';
import tutorial4 from '../../../static/tutorial/4.png';
import description from './description';


const tutorialImages = [
  tutorial0,
  tutorial1,
  tutorial2,
  tutorial3,
  tutorial4,
];

const Tutorial = ({location}) => {
  const elementRef = useRef();
  const mobElementRef = useRef();
  const arr = [0, 1, 2, 3, 4];
  const scrollSize = arr.length - 1;
  const [scrolling, setScrolling] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [tutorialDescription, setTutorialDescription] = useState('');


  const getDesc = () => {
    description.map(item => {
      if (item.index === scrollPosition){
        setTutorialDescription(item)
      }
    })
  }

  useEffect(() => {
    getDesc()
  }, [scrollPosition])

  const scrollToBottom = () => {
    if (scrollPosition < scrollSize) setScrollPosition(scrollPosition+1);
    smoothscroll.polyfill();
    setScrolling(true);
    elementRef.current.scrollTo({
      top:
        elementRef.current.scrollTop +
        elementRef.current.firstElementChild.offsetHeight,
      behavior: 'smooth',
    });
    setTimeout(() => setScrolling(false), 500);
  };

  const scrollToTop = () => {
    if (scrollPosition > 0) setScrollPosition(scrollPosition-1);
    smoothscroll.polyfill();
    setScrolling(true);
    elementRef.current.scrollTo({
      top:
        elementRef.current.scrollTop -
        elementRef.current.firstElementChild.offsetHeight,
      behavior: 'smooth',
    });
    setTimeout(() => setScrolling(false), 500);
  };

  const scrollRight = () => {
    if (scrollPosition < scrollSize) setScrollPosition(scrollPosition+1);
    smoothscroll.polyfill();
    setScrolling(true);
    mobElementRef.current.scrollTo({
      left:
        mobElementRef.current.scrollLeft +
        mobElementRef.current.firstElementChild.offsetWidth,
      behavior: 'smooth',
    });
    setTimeout(() => setScrolling(false), 500);
  };

  const scrollLeft = () => {
    if (scrollPosition > 0) setScrollPosition(scrollPosition-1);
    smoothscroll.polyfill();
    setScrolling(true);
    mobElementRef.current.scrollTo({
      left:
        mobElementRef.current.scrollLeft -
        mobElementRef.current.firstElementChild.offsetWidth,
      behavior: 'smooth',
    });
    setTimeout(() => setScrolling(false), 500);
  };

  return (
    <Layout location={location}>
      <div className={styles.tutorial}>
        <div className={styles.tutorial__container}>
          <h1>Tutorial</h1>
          <p>
            Welcome to CELLR! The one stop for you to curate every part of your
            wine life, connect with vineyards, insight on specials, and locate
            hard to source wines!
          </p>
          <div className={styles.tutorial__line}>
            <span></span>
          </div>
          <h3>{tutorialDescription.title}</h3>
          <p>
            {tutorialDescription.text}
          </p>
        </div>
        <div className={styles.tutorial__sliderContainer}>



          <div className={styles.tutorial__slider} ref={elementRef}>
            {arr.map((el) => (
              <img key={el} src={tutorialImages[el]} alt="tutorial" />
            ))}
          </div>
          <div className={styles.tutorial__arrows}>
            <button disabled={scrolling}
                    style={{visibility: scrollPosition === 0 ? 'hidden' : 'visible'}}
                    onClick={scrollToTop}
                    aria-label="Up">
              <FontAwesomeIcon icon={faArrowUp} />
            </button>
            <button
              disabled={scrolling}
              style={{visibility: scrollPosition === scrollSize ? 'hidden' : 'visible'}}
              onClick={scrollToBottom}
              aria-label="Down"
            >
              <FontAwesomeIcon icon={faArrowDown} />
            </button>
          </div>
        </div>
      </div>

      <div className={styles.tutorialMobile}>
        <div className={styles.tutorialMobile__container}>
          <div>
            <h1>Tutorial</h1>
            <p>
              Welcome to CELLR! The one stop for you to curate every part of
              your wine life, connect with vineyards, insight on specials, and
              locate hard to source wines!
            </p>
          </div>
        </div>
        <div className={styles.tutorialMobile__sliderContainer}>
          <button disabled={scrolling}
                  style={{visibility: scrollPosition === 0 ? 'hidden' : 'visible'}}
                  onClick={scrollLeft}
                  aria-label="Left">
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <div className={styles.tutorialMobile__slider} ref={mobElementRef}>
            {arr.map((el) => (
              <img key={el} src={tutorialImages[el]} alt="tutorial" />
            ))}
          </div>
          <button disabled={scrolling}
                  style={{visibility: scrollPosition === scrollSize ? 'hidden' : 'visible'}}
                  onClick={scrollRight}
                  aria-label="Right">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
        <div className={styles.tutorialMobile__container}>
          <h3>{tutorialDescription.title}</h3>
          <p>
            {tutorialDescription.text}
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Tutorial;
